import * as React from 'react';
import MarketingPage from '@pages/marketing';
import { GetServerSideProps } from 'next';
import { auth } from '@acme/auth';

export default function HomeIndexPage() {
  return <MarketingPage />;
}

export const getServerSideProps: GetServerSideProps = async ctx => {
  const session = await auth(ctx);

  if (session?.user) {
    return {
      redirect: {
        permanent: false,
        destination: `/dashboard`
      }
    };
  }

  return {
    props: {}
  };
};
